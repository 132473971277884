import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  constructor(
    private http: HttpClient,
    private util: UtilService
  ) { }
  // baseUrlApp = 'http://localhost:3500/api/v1/'
  baseUrlApp = environment.app_url;
  baseUrl = environment.url;
  header: any;
  headerFile: any;
  headerNoAuth: any;
  headerRemita: any;
  idPotencyKey = Math.ceil(Math.random() * 10430).toString();
  a;

  getRequest(getUrl) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http
      .get(`${this.baseUrl + getUrl}`, { headers: header })
      .toPromise();
  }
  getRequestExternal(getUrl) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http.get(this.baseUrlApp + getUrl, { headers: header }).toPromise();
  }
  postRequestExternal(getUrl, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http.post(this.baseUrlApp + getUrl, data, { headers: header }).toPromise();
  }
  postRequest(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http
      .post(`${this.baseUrl + url}`, data, { headers: header })
      .toPromise();
  }
  patchRequest(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http
      .patch(`${this.baseUrl + url}`, data, { headers: header })
      .toPromise();
  }
  putRequest(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http
      .put(`${this.baseUrl + url}`, data, { headers: header })
      .toPromise();
  }
  putRequestFile(url, data) {
    const headerFile = new HttpHeaders({
      Accept: 'application/json, text/plain',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http
      .put(`${this.baseUrl + url}`, data, { headers: headerFile })
      .toPromise();
  }
  deleteRequest(url) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${this.util.getToken()}`,
    });
    return this.http
      .delete(`${this.baseUrl + url}`, { headers: header })
      .toPromise();
  }
  loginExternal(data) {
    return this.postRequest('admin/login', data);
  }
  login(data) {
    return this.postRequest('staff/login', data);
  }
  registerAdmin(data) {
    return this.postRequest('admin/register', data);
  }
  getCompletedApplications(skip, lim, search: object) {
    return this.postRequestExternal(`admin/applications/completed?skip=${skip}&limit=${lim}`, search);
  }
  regApp(data) {
    return this.postRequestExternal('admin/applications/app', data);
  }
  getAllPayments(skip, lim) {
    return this.getRequestExternal(`admin/applications/paid?skip=${skip}&limit=${lim}`);
  }
  getSummary() {
    return this.getRequestExternal(`admin/applications/summary`);
  }
  logoutAll() {
    return this.postRequest('staff/logout', {});
  }
  completeResetPassword(data) {
    return this.postRequest('', data);
  }
  resetPassword(data) {
    return this.postRequest('', data);
  }
  createSession(data) {
    return this.postRequest('session', data);
  }
  getCurrentSession() {
    return this.getRequest('session/current');
  }
  getSessions() {
    return this.getRequest('session');
  }
  updateSemester(id, data) {
    return this.putRequest(`session/${id}`, data);
  }
  updateDepartment(id, data) {
    return this.putRequest(`department/${id}`, data);
  }
  deleteDepartment(id) {
    return this.deleteRequest(`department/${id}`);
  }
  deleteStudents(data: object) {
    return this.postRequest(`auth/delete/`, data);
  }
  createDepartment(data) {
    return this.postRequest('department', data);
  }
  getDepartments() {
    return this.getRequest('department');
  }
  createCourse(data) {
    return this.postRequest('course', data);
  }
  getCourses() {
    return this.getRequest('course');
  }
  deleteCourse(id) {
    return this.deleteRequest(`course/${id}`);
  }
  updateCourse(id, data) {
    return this.putRequest(`course/${id}`, data);
  }
  uploadExistingStudents(data) {
    return this.putRequestFile(`auth/oldStudent`, data);
  }
  uploadAdmissionList(data) {
    return this.putRequestFile(`auth/admitted`, data);
  }
  createPaymentType(data) {
    return this.postRequest('payment/add', data);
  }
  getPaymentTypes() {
    return this.getRequest('payment');
  }
  deletePaymentType(id) {
    return this.deleteRequest(`payment/${id}`);
  }

  updatePaymentType(id, data) {
    return this.putRequest(`payment/${id}`, data);
  }
  generateRRR(data) {
    return this.postRequest('pay/generateRefAdmin', data);
  }
  verifyPaymentByLevel(id: string, level: string) {
    return this.getRequest(`pay/verifyPayment?studentId=${id}&level=${level}`);
  }
  verifyRRR(data) {
    return this.postRequest('pay/adminConfirmRef', data);
  }
  allPayments(page: number, query: { session: string, department: string, paymentCompleted: boolean }) {
    return this.getRequest(`pay/getAllPayments?pageNumber=${page}&department=${query.department}&session=${query.session}&paymentCompleted=${query.paymentCompleted}`);
  }
  dashboardSummary(session?: string) {
    const query = session ? `?session=${session || ''}` : ''
    return this.patchRequest(`staff/dashboard${query}`, {});
  }
  addSingleAdmissionList(data) {
    return this.postRequest('auth/manualAdmit', data);
  } paymentCompleted
  addSingleStudentList(data) {
    return this.postRequest('auth/oldSingleStudent', data);
  }
  updateStudentProfile(id: string, data: FormData) {
    return this.putRequestFile(`auth/studentUpdate?studentId=${id}`, data);
  }
  createStaff(data) {
    return this.postRequest('staff', data);
  }
  getAllStaff() {
    return this.getRequest('staff');
  }
  getSingleStaff(id) {
    return this.getRequest(`staff/${id}`);
  }
  deleteStaff(id) {
    return this.deleteRequest(`staff/${id}`);
  }
  updateStaff(id, data) {
    return this.putRequest(`staff/${id}`, data);
  }
  getAdmittedStudents(filter, page: number) {
    return this.getRequest(`auth/unConfirmStudents?name=${filter?.name}&session=${filter?.session}&department=${filter?.dept}&pageNumber=${page}`);
  }
  getStudents(filter, page: number) {
    return this.getRequest(`auth/confirmStudents?name=${filter?.name}&level=${filter?.level}&department=${filter?.dept}&pageNumber=${page}`);
  }
  confirmStudent(data, id, dept) {
    return this.putRequest(`auth/successfullyAdmitted/${id}?departmentId=${dept}`, data);
  }
  getAdmissionStatistics() {
    return this.getRequest('department');
  }
  getPaymentStatistics() {
    return this.getRequest('department');
  }
  searchStudent(name: string) {
    return this.getRequest(`auth/searchStudents?search=${name}`);
  }
  updateAdmission(id, data) {
    return this.putRequest(`auth/studentUpdate?studentId=${id}`, data);
  }
}
