<!-- <ngx-alerts></ngx-alerts> -->
<div class="login">
  <div class="card-wrapper">
    <div class="card col-md-5">
      <div class="card-logo">
        <img src="../../../assets/img/logo.jpeg" alt="" class="logo mb-4" />
      </div>
      <h2 class="text-boro-primary text-center f-14">
        Adamawa State College of Health Technology, Michika
      </h2>
      <p class="h4 text-center text-app-primary mb-3">Log In</p>
      <form (ngSubmit)="login()" [formGroup]="loginForm">
        <p class="error">{{ msg }}</p>
        <div class="form-group mb-4">
          <label class="f-12 text-boro-grey" for="exampleInputEmail1"
            >Email address</label
          >
          <input
            type="email"
            class="form-control"
            formControlName="email"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
        </div>
        <div class="form-group mb-4">
          <div class="d-flex justify-content-between">
            <label class="f-12 text-boro-grey" for="exampleInputPassword1"
              >Password
            </label>
            <label
              class="f-10 text-boro-grey text-right"
              style="cursor: pointer"
            >
              Forgot password
            </label>
          </div>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            id="exampleInputPassword1"
            placeholder="Password"
          />
        </div>
        <button
          type="submit"
          class="btn bg-app-primary w-100 text-white mb-2"
          [disabled]="!loginForm.valid || loader"
          [ngClass]="{ ban: loader == true }"
        >
          {{ loader ? "logging in..." : "Login" }}
        </button>
      </form>
    </div>
  </div>
</div>
<div class="footer"></div>
