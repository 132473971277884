import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ExportToCsv } from 'export-to-csv';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  encryptSecretKey = 'diego';
  msg: string;
  logostring: string;
  subj: any;
  constructor(
    private router: Router,
    private snackBar: MatSnackBar) { }

  private unconfirmedSubject = new BehaviorSubject<any>('');
  unconfirmedList = this.unconfirmedSubject.asObservable();

  private confirmedSubject = new BehaviorSubject<any>('');
  confirmedList = this.confirmedSubject.asObservable();

  private departmentSubject = new BehaviorSubject<any>('');
  departmentList = this.departmentSubject.asObservable();

  private paymentSubject = new BehaviorSubject<any>('');
  paymentList = this.paymentSubject.asObservable();

  private courseSubject = new BehaviorSubject<any>('');
  courseList = this.courseSubject.asObservable();

  private curSessionSubject = new BehaviorSubject<any>('');
  currentSession = this.curSessionSubject.asObservable();

  private summarySubject = new BehaviorSubject<any>('');
  summaryData = this.summarySubject.asObservable();

  private sessionSubject = new BehaviorSubject<any>('');
  sessionData = this.sessionSubject.asObservable();

  storeDepartments(data) {
    this.departmentSubject.next(data);
  }
  storeSession(data) {
    this.sessionSubject.next(data);
  }
  setCurrentSession(data) {
    this.curSessionSubject.next(data);
  }
  storeUncomfirmed(data) {
    this.unconfirmedSubject.next(data);
  }
  storeComfirmed(data) {
    this.confirmedSubject.next(data);
  }
  storePayment(data) {
    this.paymentSubject.next(data);
  }
  storeSummary(data) {
    this.summarySubject.next(data);
  }
  storeCourse(data) {
    this.courseSubject.next(data);
  }
  async getToday() {
    const d = new Date();
    const formatted = await this.formatDate(d);
    return formatted;
  }
  async getMondayOfCurrentWeek() {
    const d = new Date();
    const day = d.getDay();
    const dateObj = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() + (day === 0 ? -6 : 1) - day
    );
    const formatted = await this.formatDate(dateObj);
    return formatted;
  }
  formatDate(dateObj) {
    const month = dateObj.getMonth() + 1;
    const monthStr = month < 10 ? '0' + month : month;
    const dateStr =
      dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate();
    const year = dateObj.getFullYear().toString();
    return dateStr + '/' + monthStr + '/' + year;
  }
  async getSundayOfCurrentWeek() {
    const d = new Date();
    const day = d.getDay();
    const dateObj = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() + (day === 0 ? 0 : 7) - day
    );
    const formatted = this.formatDate(dateObj);
    return formatted;
  }
  async getFirstDayOfCurrentMonth() {
    const d = new Date();
    const dateObj = new Date(d.getFullYear(), d.getMonth(), 1);
    const formatted = this.formatDate(dateObj);
    return formatted;
  }
  async getAllDatesOfCurrentWeek() {
    const values = [1, 2, 3, 4, 5, 6, 7];
    const days = [];
    const d = new Date();
    const day = d.getDay();
    values.forEach((el) => {
      const dateObj = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() + (day === 0 ? 0 : el) - day
      );
      const formatted = this.formatDate(dateObj);
      const obj = { sn: el, date: formatted };
      days.push(obj);
    });
    return days;
  }
  async getLastDayOfCurrentMonth() {
    const d = new Date();
    const dateObj = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    const formatted = this.formatDate(dateObj);
    return formatted;
  }
  async getFirstDayOfTheYear() {
    const dateObj = new Date(new Date().getFullYear(), 0, 1);
    const formatted = this.formatDate(dateObj);
    return formatted;
  }

  async getLastDayOfTheYear() {
    const dateObj = new Date(new Date().getFullYear(), +12, 0);
    const formatted = await this.formatDate(dateObj);
    return formatted;
  }
  isAthourized(allowedUsertypes: string[]): any {
    // check if the list of allowedusertpes for aroute is empty, if empty, authorize the user to access the page
    if (allowedUsertypes == null || allowedUsertypes.length === 0) {
      return true;
    }
    const authUsertype = this.getUserObject().role;
    // console.log("role:", authUsertype)
    return allowedUsertypes.includes(authUsertype);
  }
  setUserObject(userObject) {
    localStorage.setItem(
      'ki',
      CryptoJS.AES.encrypt(
        JSON.stringify(userObject),
        this.encryptSecretKey
      ).toString()
    );
  }

  getUserObject() {
    const user = localStorage.getItem('ki');
    if (user) {
      const bytes = CryptoJS.AES.decrypt(user, this.encryptSecretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }

  geRemitaData() {
    const remita = localStorage.getItem('remita');
    if (remita) {
      const bytes = CryptoJS.AES.decrypt(remita, this.encryptSecretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }

  setRemitaData(data) {
    localStorage.setItem(
      'remita',
      CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey
      ).toString()
    );
  }

  setToken(token) {
    localStorage.setItem(
      'xxx',
      CryptoJS.AES.encrypt(
        JSON.stringify(token),
        this.encryptSecretKey
      ).toString()
    );
  }

  getToken() {
    const token = localStorage.getItem('xxx');
    if (token) {
      const bytes = CryptoJS.AES.decrypt(token, this.encryptSecretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }

  // Check if an object is empty
  isEmpty(obj: object) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  setStudent(data) {
    localStorage.setItem(
      'xtd',
      CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey
      ).toString()
    );
  }

  getStudent() {
    const data = localStorage.getItem('xtd');
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }

  dialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '576px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;
    return dialogConfig;
  }
  removeUser() {
    localStorage.removeItem('ki');
    localStorage.removeItem('xxx');
  }
  isSuperAdmin(): boolean {
    if (this.getUserObject().role === 'superAdmin') {
      return true;
    }
    return false;
  }
  isLoggedIn(): boolean {
    if (localStorage.getItem('ki') && localStorage.getItem('xxx')) {
      return true;
    }
    return false;
  }
  logout() {
    this.removeUser();
    this.router.navigateByUrl('/');
  }
  scroll() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  // SNACKBAR METHODS
  succesSnackbar(msg): void {
    this.snackbarConfig(
      'Success', msg, 'success-snackbar'
    );
  }

  errorSnackbar(msg): void {
    this.snackbarConfig(
      'Error', msg, 'error-snackbar'
    );
  }

  warningSnackbar(msg): void {
    this.snackbarConfig(
      'Warning', msg, 'warning-snackbar'
    );
  }

  snackbarConfig(title, msg, theme): void {
    this.snackBar.open(title, msg, {
      duration: 7000,
      verticalPosition: 'top',
      // horizontalPosition: 'right',
      panelClass: [theme],
    });
  }

  // CSV EXPORT
  export(data, headers, title): any {
    const options = {
      fieldSeparator: ',',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: `${title}-${Math.floor(Math.random() * 1000) + 1} `,
      useTextFile: false,
      useBom: true,
      headers: []
    };
    options.headers = headers;
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  // Get available pages for requested data
  dataPages(size: number): Array<any> {
    // to round value to next whole number if it has a fraction
    if (size % 1 !== 0) {
      size = Math.trunc(size) + 1;
    }
    const pages = [];
    for (let index = 0; index < size; index++) {
      const page = size - index;
      pages.push(page);
    }
    return _.sortBy(pages); // return data in ascending order
  }
}
