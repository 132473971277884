import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Observable } from 'rxjs';
import { UtilService } from './Services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private alert: AlertService,
    private authservice: UtilService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowedusertypes = next.data.allowedusertypes;
    const isAuthorized = this.authservice.isAthourized(allowedusertypes);
    const loggedin = this.authservice.isLoggedIn();
    if (loggedin) {
      if (!isAuthorized) {
        this.router.navigateByUrl('/');
        this.alert.warning('You are not allowed to access this page');
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/']);
    }
  }
}
