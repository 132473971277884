import { Router } from '@angular/router';
import { CrudService } from 'src/app/Services/crud.service';
import { UtilService } from './../../Services/util.service';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loader = false;
  msg = '';
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private crud: CrudService,
    private util: UtilService,
    private alert: AlertService
  ) {
    this.loginForm = this.formbuilder.group({
      password: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }
  async login() {
    try {
      this.loader = true;
      const data: any = await this.crud.login(this.loginForm.value);
      this.util.setToken(data.data.token);
      this.util.setUserObject(data.data);
      this.alert.success(data.message);
      this.router.navigate(['/portal']);
    } catch (err) {
      this.alert.danger(err.error.message || 'Network error');
      this.loader = false;
    }
  }

}
