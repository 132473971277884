<mat-progress-bar
  mode="query"
  color="warn"
  *ngIf="isShowingRouteLoadIndicator"
  style="z-index: 1"
>
</mat-progress-bar>
<ngx-alerts></ngx-alerts>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <span class="text-white">Loading, please wait...</span>
</ngx-spinner>
<router-outlet></router-outlet>
