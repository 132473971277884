import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { AuthGuard } from './auth.guard';

// canActivate: [AuthGuard],
const routes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', component: LoginComponent, data: { title: 'Login | Admin' } },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    data: {
      title: 'Adamawa College of Health Technology | Dashboard'
    },
    loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
