
const proxy = 'https://cors-proxy-bypas.herokuapp.com/';

export const environment = {
  production: false,
  hash: 'oayastechnigerialtd',
  // url: 'https://acht-portal.herokuapp.com/api/v1/',
  url: 'https://acht-production.herokuapp.com/api/v1/',
  app_url: 'http://localhost:3500/api/v1/'
  // app_url: "https://coemubi.herokuapp.com/api/v1/"
};

